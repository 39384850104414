import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

export function initScrolly() {
    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(ScrollToPlugin)
    return [...document.querySelectorAll('.b-horizontalscroller')].map(e => new Scrolly(e))
}

export class Scrolly {
    constructor(element) {

        const wrapper = element;
        const horizontalScroller = element.querySelector('.b-horizontalscroller__inner');
        const maxScroll = horizontalScroller.scrollWidth - horizontalScroller.clientWidth;
        const triggerId = 'trigger-' + Math.random() * 999999;

        // Create basic scroll timeline
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: wrapper,
                id: triggerId,
                pin: true,
                scrub: true,
                end: () => `+=${maxScroll}`, // change scroll duration if more stuff should happen
                invalidateOnRefresh: true
            },
        });

        // Add animations to timeline
        tl
            .addLabel("start")
            .to(horizontalScroller, { scrollTo: {x: maxScroll,y:0} })
            .addLabel("end");
    }
}
