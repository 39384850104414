export class Menu{
    constructor(){
        this.siteHeader = document.querySelector(".site-header");
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }

    handleMenuClick(event){
        this.siteHeader.classList.toggle("menu-opened");
    }
}
