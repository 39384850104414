/**
 * Debounce events that otherwise fire too often.
 */
export function initEventDebounce() {
    /**
     * Resize Event
     */
    var scrollFiring = false;

    document.addEventListener('scroll', function () {

        if (scrollFiring === false) {
            window.requestAnimationFrame(function () {

                const ev = new CustomEvent('scroll-debounced');
                document.dispatchEvent(ev);

                scrollFiring = false;
            });
        }

        scrollFiring = true;

    }, {passive: true});


    /**
     * Resize Event
     */
    var resizeFiring = false;

    window.addEventListener('resize', function () {

        if (resizeFiring === false) {
            window.requestAnimationFrame(function () {
                const ev = new CustomEvent('resize-debounced');
                document.dispatchEvent(ev);

                resizeFiring = false;
            });
        }

        resizeFiring = true;

    }, {passive: true});
}
