const observer = new IntersectionObserver(
    ([e]) => e.target.classList.toggle('-sticky', e.intersectionRatio < 1),
    {threshold: [1]}
);

export function initStickyAnchors() {
    [...document.querySelectorAll('.b-anchor-tab:not(.initialized)')].forEach(el => {
        el.classList.add('-initialized')
        observer.observe(el)
    })
}
