import {post as apiPost} from "../../utils/api";
import Toastify from "toastify-js";

/**
 * Base class to handle ajax forms. Will be initialized for all form.ajax-form nodes.
 *
 */
export class AjaxForm {

    _dom = {
        form: null,
        submitBtn: null
    }

    _state = {
        isLoading: false,
        stateDisplayDuration: 2000,
    }

    constructor(formNode) {
        this.findNodes(formNode)
        this.initListeners();
        this.render();
    }

    /**
     * Display and set element
     *
     */
    render() {
        this._dom.form.classList.add('-initialized');
    }

    /**
     * Find all dom nodes that are required later
     *
     * @param formNode
     */
    findNodes(formNode) {
        this._dom.form = formNode
        this._dom.submitBtn = this._dom.form.querySelector('[type="submit"]')
    }

    /**
     * On form submit, make the form loading and block further requests
     */
    initListeners() {
        this._dom.form.addEventListener('submit', this.preFormSubmit.bind(this))
    }

    /**
     * Called directly on the form submit event. If not yet loading, forward the event to submit handler
     *
     * @param e
     */
    preFormSubmit(e) {
        e.preventDefault()
        if(this._state.isLoading) return;
        this.isLoading = true
        this.handleFormSubmit(e)
    }

    /**
     * Submit Handler. If the form is valid, send all data to the api. Then call respective callbacks for received case
     *
     * @param e
     * @returns {Promise<void>}
     */
    async handleFormSubmit(e) {

        if(this._dom.form.checkValidity()) {
            try {
                const res = await apiPost(this.apiRoute, this.formValues)
                const resJson = await res.json()

                if(res.status !== 200) throw new Error(resJson.data?.message)

                this._formSubmitSuccessCallback(resJson)
            } catch (e) {
                this._formSubmitErrorCallback(e)
            } finally {
                this._formSubmitFinallyCallback()
            }
        } else {
            Toastify({
                text: 'Form is not valid',
                className: "error",
                duration: 5000,
                gravity: "bottom",
                position: "right",
            }).showToast();
            this.isLoading = false
        }
    }

    /**
     * Always colled when a form submit request was successful
     *
     * @param res
     * @private
     */
    _formSubmitSuccessCallback(res) {
        Toastify({
            text: res.data?.message ? res.data.message : 'Success',
            className: "success",
            duration: 3000,
            gravity: "bottom",
            position: "right",
        }).showToast();
    }

    /**
     * Always called when the API did not return status 200
     *
     * @param error
     * @private
     */
    _formSubmitErrorCallback(error) {
        Toastify({
            text: error ? error : 'Error',
            className: "error",
            duration: 5000,
            gravity: "bottom",
            position: "right",
        }).showToast();
    }

    /**
     * Default case: No matter if there was an error or not, remove the loading property form the form
     *
     * @private
     */
    _formSubmitFinallyCallback() {
        this.isLoading = false
    }

    /**
     * Get all values inside the form
     *
     * @returns {{}}
     */
    get formValues() {
        const out = {}
        const data = new FormData(this._dom.form)
        for (var [key, value] of data) {
            out[key] = value
        }
        return out;
    }

    /**
     * Where should the request go to? If your URL is for example, if example.com/wp-json/do/something, apiRoute should
     * return 'do/something'
     *
     * @returns {string}
     */
    get apiRoute() {
        return this._dom.form.getAttribute('action')
    }

    /**
     * Change the loading state of the form
     *
     * @param bool
     */
    set isLoading(bool) {
        this._dom.form.classList[bool ? 'add' : 'remove']('-is-loading');
        this._dom.submitBtn.disabled = bool;
        this._state.isLoading = bool
    }
}
