import {selfOrClosest, selfOrClosestAttribute} from "./dom";

/**
 * Initialize one clicklistener on the document for event delegation and check all registered
 * this.globalClickHandlers for a match
 * => If one is found, execute the callback(event, foundDomNode).
 *
 * @private
 */
export function initGlobalClickListeners(clickHandlers) {
    document.addEventListener('click', e => {
        for(let handler of clickHandlers) {
            if(!handler.selectorType || handler.selectorType === 'class') {
                const node = selfOrClosest(e.target, handler.selector)
                if(node) {
                    handler.callback(e, node)
                    if(handler.stopPropagation) break;
                }
            } else if(handler.selectorType === 'attribute') {
                const node = selfOrClosestAttribute(e.target, handler.selector, handler.value ? handler.value : null)
                if(node) {
                    handler.callback(e, node)
                    if(handler.stopPropagation) break;
                }
            }
        }
    })
}