/**
 * Function to handle click events on share buttons
 * Call this function when a .c-share-btn titleNode is clicked
 *
 * @param {Event} event - The click event
 * @param {HTMLElement} titleNode - The titleNode that was clicked
 */
export function handleShareBtnClick(event, titleNode){
    const currentUrl = titleNode.dataset.url;

    // Create a temporary textarea element to facilitate copying to the clipboard
    const textarea = document.createElement('textarea');
    textarea.value = currentUrl;

    // Append the textarea to the DOM
    document.body.appendChild(textarea);

    // Select and copy the text in the textarea
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea from the DOM
    document.body.removeChild(textarea);

    // Add class for effect, then remove
    if (!titleNode.classList.contains('-clicked')){
        titleNode.classList.add('-clicked');
        setTimeout(function (){
            titleNode.classList.remove('-clicked');
        }, 2000);
    }

    console.log('URL copied to clipboard!');
}
