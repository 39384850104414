/**
 * Provided a valid single element HTML string this function will create and return the corresponding DOM Element
 * @param str
 * @returns DOMElement
 */
const toNode = (str) => {
    const d = document.createElement('div')
    d.innerHTML = str
    return d.children[0]
}

/**
 * Providing a domElement and a classname this method will either return the DOMNode with that class if the element
 * itself or any element above (a parent) has this class, else null. Usefull for event delegation
 * @param el
 * @param className
 * @returns DOMElement|null
 */
const selfOrClosest = (el, className) => {
    return el.classList.contains(className) ? el : el.closest(`.${className}`);
}

/**
 * Much like selfOrClosest, but for data-attributes
 * @param node
 * @param key
 * @param val
 * @returns {*|null}
 */
function selfOrClosestData(node, key, val = null) {
    return selfOrClosestAttribute(node, `data-${key}`, val)
}

/**
 * Much like selfOrClosest, but for attributes
 * @param node
 * @param attribute
 * @param val
 * @returns {*|null}
 */
function selfOrClosestAttribute(node, attribute, val = null) {
    if (val !== null) {
        return node.getAttribute(attribute) === val
            ? node
            : node.closest(`[${attribute}="${val}"]`)
    }
    return node.hasAttribute(attribute)
        ? node
        : node.closest(`[${attribute}]`)
}

/**
 * Get the transform:translateY(X) value of an element
 * @param node
 * @param computedStyle
 * @returns {number|number}
 */
export function getComputedTransformY({node = null, computedStyle = null}) {
    if (computedStyle === null) {
        computedStyle = getComputedStyle(node)
    }
    const out = computedStyle.transform ? parseFloat(computedStyle.transform.replace(/matrix\(.*, ([0-9.]*)\)$/, '$1')) : 0
    return out || 0
}

/**
 * Get the transform:translateX(X) value of an element
 * @param node
 * @param computedStyle
 * @returns {number|number}
 */
export function getComputedTransformX({node = null, computedStyle = null}) {
    if (computedStyle === null) {
        computedStyle = getComputedStyle(node)
    }
    const out = computedStyle.transform ? parseFloat(computedStyle.transform.replace(/matrix\(.*, ([0-9.]*), [0-9.]*\)$/, '$1')) : 0
    return out || 0
}

/**
 * Calculate to global offset of an element on a page
 * @param el
 * @returns {{x: number, y: (number|number), isFixed: boolean}}
 */
export function getCumulativeOffset(el) {
    var _x = 0;
    var _y = 0;
    let fixed = false
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {

        const computedStyle = getComputedStyle(el)

        fixed = fixed || computedStyle.position === 'fixed'

        _x += el.offsetLeft + getComputedTransformX({computedStyle})
        _y += el.offsetTop + getComputedTransformY({computedStyle})
        el = el.offsetParent
    }

    return {y: document.body.classList.contains('logged-in') ? _y - 32 : _y, x: _x, isFixed: fixed};
}

/**
 * Check if a given element is within the viewport. Overflow 20 for example returns true 20 pixels before the element
 * is in the viewport
 * @param el
 * @param overflow
 * @returns {boolean|*}
 */
function isInViewport(el, overflow) {
    overflow = overflow || 0;
    const rect = el.getBoundingClientRect();
    try {
        return coordinatesAreInViewport(rect.top, rect.top + el.clientHeight, 0 - overflow, window.innerHeight + overflow)
    } catch (e) {
        return false;
    }
}

/**
 * Check if given coordinates are on the screen at the moment
 *
 * @param y11
 * @param y12
 * @param y21
 * @param y22
 * @returns {boolean}
 */
function coordinatesAreInViewport(y11, y12, y21, y22) {
    y21 = y21 === undefined ? window.scrollY : y21;
    y22 = y22 === undefined ? window.scrollY + window.innerHeight : y22;
    return !((y11 < y21 && y12 < y21 || y11 > y22 && y12 > y22))
}

/**
 * Apply extensions to all dom elements
 */
function initDomExtensions() {
    /**
     * This allows you to set element styles through element.css{'background':'red', 'color':'blue'}
     * @param attr
     * @param val
     */
    Element.prototype.css = function (attr, val) {
        if (typeof attr === 'string' && val) {
            this.style[attr] = val;
        } else if (typeof attr === 'object') {
            Object.assign(this.style, attr)
        }
    };
}


export {
    selfOrClosestAttribute,
    selfOrClosestData,
    selfOrClosest,
    toNode,
    isInViewport,
    coordinatesAreInViewport,
    initDomExtensions
};
