import Splide from "../../../node_modules/@splidejs/splide";

export function initComponentSplideGallery() {
    [...document.querySelectorAll(".splide.galleryslider")].map((el) => new ComponentSplideGallery(el));
}


export class ComponentSplideGallery {

    _dom = {
        slider: null,
    };

    _splide = null;

    constructor(el) {
        if (!el) return;

        this._dom.slider = el;
        this._dom.bar    = el.querySelector( '.splide__progress--bar' );

        this._splide =  new Splide(el, {
            type: 'loop',
            gap: '1.25rem',
            pagination: false,
            focus    : 0,
            omitEnd  : true,
            arrows : true,
            perPage: 1,
            autoplay: false,
            speed: 500,
        }).mount()

        if(this._dom.bar){
            this._progress();
        }
        this._initListeners();
    }


    _progress = () => {
        this._dom.end  = this._splide.Components.Controller.getEnd() + 1;
        this._dom.rate = Math.min( ( this._splide.index + 1 ) / this._dom.end, 1 );
        this._dom.bar.style.width = String( 100 * this._dom.rate ) + '%';
    }

    _initListeners() {
        // Updates the bar width whenever the carousel moves:
        if(this._dom.bar){
            this._splide.on('mounted move', this._onMountedMove);
        }
    }

    _onMountedMove = () => {
        this._progress();
    }
}

