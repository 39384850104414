import {get} from '../../../assets/js/utils/api';
import {toNode} from "../../../assets/js/utils/dom";

export function initPostLists() {
    return [...document.querySelectorAll('.b-post-list:not(.-initialized)')].map(node => new PostList(node))
}

class PostList {

    /**
     * All DOM Nodes required to run the application
     *
     * @type {{buttons: {loadMore: null, filters: []}, postList: null, wrapper: null}}
     * @private
     */
    _dom = {
        wrapper: null,
        postList: null,
        buttons: {
            filters: [],
            loadMore: null
        }
    }

    /**
     * The current state of the applications. These values are checked when executing the ajax request and
     * can be changed over time
     *
     * @type {{isLoading: boolean, postTypes: null, term: null, page: number, taxonomy: null}}
     * @private
     */
    _state = {
        isLoading: false,
        page: 1,
        taxonomy: null,
        term: null,
        postTypes: null,
    }

    /**
     * The initial configuration of the application. This will only be changed on construction and then stays
     * the same. It tells the _state how to reset for example
     *
     * @type {{postTypes: [], perPage: number, orderBy: string, order: string}}
     * @private
     */
    _config = {
        postTypes: [],
        perPage: -1,
        order: 'desc',
        orderBy: 'date'
    }

    constructor(wrapper) {
        this._dom.wrapper = wrapper

        this._state.page = parseInt(wrapper.dataset.page)

        this._config.postTypes = wrapper.dataset.postTypes.split(',')
        this._config.perPage = parseInt(wrapper.dataset.perPage)
        this._config.order = wrapper.dataset.order
        this._config.orderBy = wrapper.dataset.orderBy

        this.findNodes()
        this.attachListeners()
    }

    findNodes() {
        this._dom.buttons.filters = [...this._dom.wrapper.querySelectorAll('[data-action="filter"]')]
        this._dom.buttons.loadMore = this._dom.wrapper.querySelector('[data-action="load-more"]')
        this._dom.postList = this._dom.wrapper.querySelector('.b-post-list__posts')
    }

    attachListeners() {
        this._dom.buttons.filters.forEach(btn => {
            btn.addEventListener('keydown', e => {
                if(e.key === 'Escape') this.applyStateFilter(btn)
            })
            btn.addEventListener('click', e => {
                this.applyStateFilter(btn)
            })
        })

        this._dom.buttons.loadMore?.addEventListener('click', e => this.loadMore())
        this._dom.buttons.loadMore?.addEventListener('keydown', e => {
            if(e.key === 'Escape') this.loadMore()
        })
    }

    /**
     * Updates the state and runs the filter if a specific filter button is clicked
     *
     * @param filterButton
     * @returns {Promise<void>}
     */

    async applyStateFilter(filterButton) {

        if(this._state.isLoading) return;

        this._dom.wrapper.dataset.activeAction = 'filter'
        this._dom.buttons.filters.forEach(btn => btn.setAttribute('aria-selected', btn === filterButton ? 'true' : 'false'))

        if(filterButton.dataset.type === 'reset') {
            this._state.term = null
            this._state.taxonomy = null
            this._state.postTypes = null
            this._state.page = 1
        } else if(filterButton.dataset.type === 'WP_Post_Type') {
            this._state.postTypes = filterButton.dataset.postType
            this._state.page = 1
        } else if(filterButton.dataset.type === 'WP_Term') {
            this._state.taxonomy = filterButton.dataset.tax
            this._state.term = filterButton.dataset.term
            this._state.page = 1
        }

        const res = await this.applyState()
        this._dom.postList.innerHTML = res.html;

    }

    /**
     * Updates the pagination if the loadmore button is clicked and executes the filter
     *
     * @returns {Promise<void>}
     */
    async loadMore() {

        if(this._state.isLoading) return;

        this._dom.wrapper.dataset.activeAction = 'load-more'
        this._state.page += 1;

        const res = await this.applyState()
        const htmlContent = toNode(`<div>${res.html}</div>`);
        [...htmlContent.children].forEach(child => this._dom.postList.append(child))

    }

    /**
     * Apply the current _state settings to the post list by sending them to the api and then handling the response
     *
     * @returns {Promise<*>}
     */
    async applyState() {

        this._state.isLoading = true;
        this._dom.wrapper.setAttribute('aria-busy', 'true')

        const data = {
            page: this._state.page,
            postTypes: this._state.postTypes || this._config.postTypes,
            perPage: this._config.perPage,
            orderBy: this._config.orderBy,
            order: this._config.order,
        }

        if(this._state.taxonomy) data.taxonomy = this._state.taxonomy
        if(this._state.term) data.term = this._state.term

        let json

        try {
            const res = await get('block-post-list/index', data)
            json = await res.json()
            if(this._dom.buttons.loadMore) {
                this._dom.buttons.loadMore.style.display = json.hasMorePages ? 'block' : 'none'
            }
            return json
        } catch (error) {
            console.log(error)
            window.alert('Something went wrong....')
        } finally {
            if(this._dom.buttons.loadMore) {
                this._dom.buttons.loadMore.style.display = json.hasMorePages ? 'block' : 'none'
            }
            this._dom.wrapper.setAttribute('aria-busy', 'false')
            this._state.isLoading = false;
        }
    }
}