export async function wpAdminAjaxRequest(url = '', data = {}) {
    const response = await fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return response.json();
}

/**
 * Make get request to api. host/wp-json/ is pre-set. As route, for example for .com/wp-json/do/something
 * online set route = 'do/something'
 *
 * @param route
 * @param data
 * @param headers
 * @returns {Promise<any>}
 */
export const get = async (route, data, headers = {}) => {

    if (appConfig && appConfig.restNonce) {
        headers['X-WP-Nonce'] = appConfig.restNonce
    }

    if(data) {
        for(let key in data) {
            route += (route.includes('?') ? `&` : '?') + `${key}=${encodeURIComponent(data[key])}`
        }
    }

    return await fetch(!route.includes('://') ? `${appConfig.wpJsonRoot}${route}` : route, {
        headers: headers
    })
}

/**
 * Make post request to api. host/wp-json/ is pre-set. As route, for example for .com/wp-json/do/something
 * online set route = 'do/something'
 *
 * @param route
 * @param data
 * @param headers
 * @returns {Promise<any>}
 */
export const post = async (route, data, headers = {}) => {

    headers['Content-Type'] = headers['Content-Type'] ? headers['Content-Type'] : 'application/json';


    if (appConfig && appConfig.restNonce) {
        headers['X-WP-Nonce'] = appConfig.restNonce
    }

    return await fetch(!route.includes('://') ? `${appConfig.wpJsonRoot}${route}` : route, {
        method        : 'POST',
        cache         : 'no-cache',
        credentials   : 'same-origin',
        headers       : headers,
        referrerPolicy: 'no-referrer',
        body          : JSON.stringify(data)
    });
}

export const validateField = (el) =>  {
    if(!el.value) el.setCustomValidity(el.dataset.required);
    else el.setCustomValidity('');
    
    if(!el.checkValidity()) {
        if(el.value) el.setCustomValidity(el.title);
        el.reportValidity();
        return false;
    }
    return true;
}

export const formatValues = (form, formData) =>  {
    const fieldNames = new Set();
    Array.from(form.querySelectorAll('input[type="checkbox"]')).forEach((el) => fieldNames.add(el.name));
    const uniques = Array.from(fieldNames);

    uniques.forEach((name) => {
        const values = formData.getAll(name);
        if(values.length > 1) {
            const formatValues = values.join(', ');
            formData.delete(name);
            formData.append(name, formatValues);
        }
    });

    formData.append('aktion', 'showFormCheck');
    formData.append('extern', 1);

    return formData;
}

export const sendDataToMailambition = async(url, data) =>  {
    if((appConfig && appConfig.newsletterApiUrl) && (data && data?.suscribe)) {
        await get(appConfig.newsletterApiUrl, data,
            {
                'Origin': window.location.protocol + "//" + window.location.host,
            }
        );
    }

    const res = await get(url, data,
        {
            'Origin': window.location.protocol + "//" + window.location.host,
        }
    );

    return res;
}