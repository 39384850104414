import {initGlobalClickListeners} from "./utils/event-delegation";
import {initBlockAccordion, handleAccordionClick} from "../../components/accordion/assets/script";
import {initComponentSplideGallery} from "../../blocks/gallery-slider/assets/script";
import {initEventDebounce} from "./utils/events";
import {initScrolly} from "../../blocks/horizontal-scroller/assets/script";
import {initPostLists} from "../../blocks/post-list/assets/script";
import {initStickyAnchors} from "../../blocks/anchor-tab/assets/script";
import {initComponentNewsletterForm} from "../../components/newsletter-form/assets/script";
import {initPostSliders} from "../../blocks/post-slider/assets/script";
import {initComponentSplideQuote} from "../../blocks/quote/assets/script";
import {initComponentSplideTeaserCarousel} from "../../blocks/teaser-carousel/assets/script";
import {handleShareBtnClick} from "../../components/share-btn/assets/script";
import {Menu} from "../../components/menu/assets/script";

(function () {

    /**
     * The global frontend-application to boot all javascript
     */
    class App {
        /**
         * Global clickHandlers for event delegation.
         * Every array entry will be added to a global document click listener and the provided callback will be
         * executed when the user clicks into the provided selector.
         *
         * - selectorType: 'class' / 'attribute'
         * - selector: 'classname' for class / 'your-attribute' for attributes (without [ ])
         * - callback: the method that will be called. Parameters: callback(event, foundDomNode)
         * - value: if selectorType is 'attribute', you can also add a value you want to query for, so eg for an
         *          attribute data-foo="bar", provide {selector: 'foo', value: 'bar'}
         *
         *
         * @type {[{callback: function(*, *): void, selector: string, selectorType: string, stopPropagation: boolean},{callback: function(*, *): void, selector: string, selectorType: string, stopPropagation: boolean},{callback: function(*, *): Promise<null>, selector: string, selectorType: string, stopPropagation: boolean}]}         */
        globalClickHandlers = [
            {
                selectorType: 'class',
                selector: 'c-accordion__title',
                callback: handleAccordionClick,
                stopPropagation: true
            },
            {
                selectorType: 'class',
                selector: 'b-content-stage__arrow',
                callback: () => window.scrollTo({top: window.innerHeight - 200, behavior: 'smooth'}),
                stopPropagation: true
            },
            {
                selectorType: 'class',
                selector: 'main-menu-btn',
                callback: new Menu().handleMenuClick,
                stopPropagation: true
            },
            {
                selectorType: 'class',
                selector: 'c-share-btn',
                callback: handleShareBtnClick,
                stopPropagation: true
            },
        ];

        /**
         * set global themeData as config variable on app (see functions.php localize script)
         *
         * @type {{wpAdminAjaxURL: string, wpJsonRoot: string, restNonce: string}}
         * @private
         */
        _config = window.appConfig;

        /**
         * store state-specific information about the application
         * @private
         */
        _state = {
            isMobile: false,
        };

        constructor() {
            console.log("application booting");
            // set global themeData as config variable on app (see /inc/setup/assets.php localize script)
            this._config = appConfig;

            this.isMobile = window.isMobile =
                /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
                (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

            initEventDebounce();
            initGlobalClickListeners(this.globalClickHandlers);

            this.initializeGutenbergBlocks();

            window.addEventListener("resize-debounced", () => {
                this.isMobile =
                    /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
                    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
            });

            new Menu();
        }


        /**
         * This method attaches all Listeners for blocks that can be placed through the gutenberg editor. This method
         * can be called multiple times while the page is loaded, as we might asynchronously import new HTML to the
         * DOM, so make sure that if you have some kind of `querySelectorAll('.block').forEach(block => init(block))`
         * logic to include a data-initialized="y" for example to only init a block once
         */
        initializeGutenbergBlocks() {
            initComponentSplideGallery();
            initBlockAccordion();
            initScrolly();
            initPostLists();
            initStickyAnchors();
            initComponentNewsletterForm();
            initPostSliders();
            initComponentSplideQuote();
            initComponentSplideTeaserCarousel();
        }

        get isMobile() {
            return this._state.isMobile;
        }

        set isMobile(isMobile) {
            this._state.isMobile = isMobile;
            document.body.classList[isMobile ? "add" : "remove"]("-mobile");
            document.body.classList[isMobile ? "remove" : "add"]("-desktop");
        }

        /**
         * State-specific information about the app
         * @returns {{}}
         */
        get state() {
            return this._state;
        }

        /**
         * Global configuration of the application
         * @returns {{wpAdminAjaxURL: string, wpJsonRoot: string, restNonce: string}}
         */
        get config() {
            return this._config;
        }
    }


    function bootApp() {
        window.app = new App()
    }

    if(document.readyState === 'loading') document.addEventListener("DOMContentLoaded", bootApp);
    else bootApp()

})();

