//{ selectorType: 'class', selector: 'c-accordion__title', callback: handleAccordionClick, stopPropagation: true },

/**
 * Initialize blocks and set conditions according to the device
 *
 */
export function initBlockAccordion() {
    [...document.querySelectorAll(".c-accordion:not(.-initialiazed)")].forEach((item) => handelAutoCollapse(item));
}

/**
 * Call this, when a .c-accordeon__title titleNode was clicked
 *
 * @param event
 * @param titleNode
 */
export function handleAccordionClick(event, titleNode) {
    const group = titleNode.closest('.c-accordion');
    const elNode = titleNode.closest('.c-accordion__row');
    const openMultiple = !!parseInt(group.dataset.multiple);

    if (!openMultiple) {
        [...group.querySelectorAll('.c-accordion__row')]
            .forEach(el => {
                if (elNode !== el) closeAccordion(el)
            })
    }

    toggleAccordion(elNode)
}

function isExpanded(node) {
    return node.classList.contains('-expanded') ||
        (window.isMobile && node.classList.contains('-expanded-mobile')) ||
        (!window.isMobile && node.classList.contains('-expanded-desktop'))
}

/**
 * Toggle the closed / open state for a single .c-accordion__el
 * @param node
 */
function toggleAccordion(node) {
    if (isExpanded(node))
        closeAccordion(node)
    else
        openAccordion(node)
}

/**
 * Collapse a single .c-accordion__row
 * @param node
 */
function closeAccordion(node) {
    node.classList.remove('-expanded');
    node.classList.remove('-expanded-mobile')
    node.classList.remove('-expanded-desktop')
}

/**
 * Expand a single .c-accordion__row
 * @param node
 */
function openAccordion(node) {
    node.classList.add('-expanded');
}

/**
 * Handel Auto Collapse
 * @param group
 */
function handelAutoCollapse(group) {
    group.classList.add('-initialiazed');
}