import Splide from "../../../node_modules/@splidejs/splide";

export function initComponentSplideTeaserCarousel() {
    [...document.querySelectorAll(".splide.b-teaser-carousel__splide")].map((el) => new ComponentSplideTeaserCarousel(el));
}

export class ComponentSplideTeaserCarousel {

    _dom = {
        slider: null,
    };

    _splide = null;

    constructor(el) {
        if (!el) return;

        this._dom.slider = el;

        this._splide =  new Splide(el, {
            type: 'loop',
            perPage: 1,
            gap: '2rem',
        }).mount()

    }
}

