import Splide from "../../../node_modules/@splidejs/splide";

export function initComponentSplideQuote() {
    [...document.querySelectorAll(".splide.b-quote__splide")].map((el) => new ComponentSplideQuote(el));
}

export class ComponentSplideQuote {

    _dom = {
        slider: null,
    };

    _splide = null;

    constructor(el) {
        if (!el) return;
        
        this._dom.slider = el;

        this._splide =  new Splide(el, {
            type: 'loop',
            perPage: 1,
            arrows: false,
        }).mount()

    }
}

