export function initPostSliders() {
  [...document.querySelectorAll(".b-post-slider:not(.-initialized)")].map((el) => new Slider(el));
}

class Slider {
  _dom = {
    slider: null,
    container: null,
    wrapper: null,
  };

  _state = {
    isDown: 0,
    startX: 0,
    scrollLeft: 0,
  };

  constructor(el) {
    if (!el) return;

    this._dom.slider = el;
    this._dom.slider.classList.add('-initialized')
    this._dom.container = el.querySelector(".b-post-slider__container");
    this._dom.wrapper = el.querySelector(".b-post-slider__container__wrapper");

    this._initListeners();
  }

  _initListeners() {
    if (this._dom.slider.getAttribute("listener") === "true") return;

    this._dom.wrapper.addEventListener("mousedown", (e) => this.start(e));
    this._dom.wrapper.addEventListener("touchstart", (e) => this.start(e));

    this._dom.wrapper.addEventListener("mousemove", (e) => this.move(e));
    this._dom.wrapper.addEventListener("touchmove", (e) => this.move(e));

    this._dom.wrapper.addEventListener("mouseleave", (e) => this.end(e));
    this._dom.wrapper.addEventListener("mouseup", (e) => this.end(e));
    this._dom.wrapper.addEventListener("touchend", (e) => this.end(e));

    this._dom.slider.setAttribute("listener", "true");
  }

  end(e) {
    this._state.isDown = false;
    this._dom.slider.classList.remove("-active");
  }

  start(e) {
    this._state.isDown = true;
    this._dom.slider.classList.add("-active");
    this._state.startX = e.pageX || (e.touches?.[0].pageX ?? 0) - this._dom.wrapper.offsetLeft;
    this._state.scrollLeft = this._dom.wrapper.scrollLeft;
  }

  move(e) {
    e.preventDefault();

    if (!this._state.isDown) return;

    const x = e.pageX || (e.touches?.[0].pageX ?? 0) - this._dom.wrapper.offsetLeft;
    const dist = x - this._state.startX;
    const scrollLeft = this._state.scrollLeft - dist;
    this._dom.wrapper.scrollLeft = scrollLeft;

    if (scrollLeft <= 0) this._dom.slider.classList.add("start");
    else this._dom.slider.classList.remove("start");

    const scrollWidth = scrollLeft + this._dom.wrapper.offsetWidth;
    if (scrollWidth >= this._dom.wrapper.scrollWidth) this._dom.slider.classList.add("end");
    else this._dom.slider.classList.remove("end");
  }
}
